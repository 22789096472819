<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-ads"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_advertisement"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_ADS.TITLE") }}
          </span>
        </div>
      </div>

      <div
        id="collapse_advertisement"
        class="collapse"
        data-parent="#accordion-form-ads"
      >
        <div class="card-body">
          <div class="d-flex flex-row justify-content-between w-100">
            <div
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0">{{
                $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_ADS.LABEL_AUTH_ADS")
              }}</label>

              <div class="d-flex flex-column">
                <b-form-checkbox
                  v-model="form.authorizedAds"
                  size="lg"
                  name="check-button"
                  switch
                />
              </div>
            </div>

            <div
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0"
                >{{
                  $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_ADS.LABEL_ADS_AUDI_ADS")
                }}
              </label>

              <div class="d-flex flex-column">
                <b-form-checkbox
                  v-model="form.authorizedAudioAds"
                  size="lg"
                  name="check-button"
                  switch
                />
              </div>
            </div>
          </div>

          <div class="d-flex flex-row align-items-sm-center mb-3 mt-3">
            <label class="mr-2 mb-0">{{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_ADS.LABEL_ADS_BACKFILL")
            }}</label>

            <div class="d-flex flex-column">
              <b-form-checkbox
                v-model="form.audioAdsBackfilled"
                size="lg"
                name="check-button"
                switch
              />
            </div>
          </div>

          <div
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 mt-3"
          >
            <label class="mr-2 w-25">{{
              $tc("ADVANCED_SETTINGS.DOMAIN.CONFIG_ADS.LABEL_SELLERS")
            }}</label>

            <div class="d-flex flex-column w-75">
              <b-form-textarea
                v-model="form.sellers"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.DOMAIN.CONFIG_ADS.LABEL_SELLERS')
                "
                class="form-control--grey"
                rows="8"
              />
            </div>
          </div>

          <div
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 mt-3"
          >
            <b-container>
              <b-row>
                <b-col>
                  <label class="mr-2 w-100">{{
                    $tc(
                      "ADVANCED_SETTINGS.DOMAIN.CONFIG_ADS.PUBLISHER_AUDIOADS_CPM"
                    )
                  }}</label>

                  <b-form-input
                    prepend="CPM"
                    v-model="form.publisherAudioAdsCpm"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="CPM"
                    type="number"
                    v-b-tooltip.hover
                    title="Publisher CPM"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <label class="mr-2 w-100">{{
                    $tc(
                      "ADVANCED_SETTINGS.DOMAIN.CONFIG_ADS.PUBLISHER_AUDIOADS_FILLRATE"
                    )
                  }}</label>

                  <b-form-input
                    prepend="%"
                    v-model="form.publisherAudioAdsFillRate"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Fill Rate"
                    type="number"
                    v-b-tooltip.hover
                    title="Publisher Fill Rate"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/store/auth.module";
import { getAttributesRead, hasAttributeAccess } from "@/common/config/acl";
import { FORM_ADVERTISEMENT } from "@/common/config/acl/domain/advertisement";
import { stringifyJson } from "@/assets/js/helper";

export default {
  name: "AdvertisementForm",

  props: {
    domainId: {
      type: String,
      required: false
    },
    formProps: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      user: auth.state.user,
      form: getAttributesRead(FORM_ADVERTISEMENT, auth.state.user.roles)
    };
  },

  mounted() {
    if (this.domainId) {
      this.form = this.formProps;
      this.form.sellers = stringifyJson(this.formProps.sellers);
    }
  },

  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        FORM_ADVERTISEMENT,
        this.user.roles,
        field,
        accessType
      );
    },

    resetData() {
      this.form = getAttributesRead(FORM_ADVERTISEMENT, this.user.roles);
    }
  }
};
</script>
