<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-advanced"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#home_highlight_programs"
        >
          <span>
            {{
              $tc(
                "ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.TITLE"
              )
            }}
          </span>
        </div>
      </div>

      <div
        id="home_highlight_programs"
        class="collapse"
        data-parentId="#accordion-form-home-highlight-programs"
      >
        <div class="card-body">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-info"
          >
            <p
              v-html="
                $tc(
                  'ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.MESSAGE'
                )
              "
            ></p>
          </div>

          <div
            v-if="hasAccess('homeHighlightPrograms', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25"
              >{{
                $tc(
                  "ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.PROGRAMS.LABEL"
                )
              }}
              <HelperTooltipHTML
                icon="mdi-help-circle"
                :text="
                  $tc(
                    'ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.PROGRAMS.HELPER'
                  )
                "
              />
            </label>

            <div class="d-flex flex-column w-75">
              <TagSelect
                :listOptions="programs"
                :buttonText="
                  $tc(
                    'ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.PROGRAMS.BUTTONTEXT'
                  )
                "
                buttonIcon="mdi-flag"
                fieldName="name"
                variant="info"
                :preSelectedElement="formData.homeHighlightPrograms"
                @change="changeHomeHighlightPrograms"
                :disabled="!hasAccess('homeHighlightPrograms', 'write')"
              />
            </div>
          </div>

          <div
            v-if="hasAccess('homeHighlightProgramsMode', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25"
              >{{
                $tc(
                  "ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.MODE.LABEL"
                )
              }}
              <HelperTooltipHTML
                icon="mdi-help-circle"
                :text="
                  $tc(
                    'ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.MODE.HELPER'
                  )
                "
              />
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-select
                v-model="formData.homeHighlightProgramsMode"
                :options="EnumHomeHighlightProgramsMode"
                class="form-control--grey"
                :disabled="!hasAccess('homeHighlightProgramsMode', 'write')"
              ></b-form-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/store/auth.module";
import TagSelect from "@/components/TagSelectV2";
import HelperTooltipHTML from "@/components/HelperTooltipHTML";
import { FORM_HOME_HIGHLIGHT_PROGRAMS } from "@/common/config/acl/domain/homeHighlightPrograms";
import {
  hasAttributeAccess,
  getAttributesRead
} from "@/common/config/acl/index";
import { EnumHomeHighlightProgramsMode } from "@/components/settings/formsDomain/domain.config";

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    programs: {
      type: Array,
      default: () => {
        return [];
      },
      required: true
    }
  },

  components: {
    TagSelect,
    HelperTooltipHTML
  },

  data() {
    return {
      EnumHomeHighlightProgramsMode,
      formData: getAttributesRead(
        FORM_HOME_HIGHLIGHT_PROGRAMS,
        auth.state.user.roles
      )
    };
  },

  mounted() {
    this.formData.homeHighlightPrograms = this.programs.filter(item =>
      this.form.homeHighlightPrograms.includes(item.id)
    );
    this.formData.homeHighlightProgramsMode = this.form.homeHighlightProgramsMode;
  },

  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        FORM_HOME_HIGHLIGHT_PROGRAMS,
        auth.state.user.roles,
        field,
        accessType
      );
    },

    changeHomeHighlightPrograms(items) {
      this.formData.homeHighlightPrograms = items;
    },

    resetData() {
      this.formData = getAttributesRead(
        FORM_HOME_HIGHLIGHT_PROGRAMS,
        auth.state.user.roles
      );
      this.formData.homeHighlightPrograms = [];
    }
  }
};
</script>
