import { ROLES } from "@/common/config/acl/roles";

const DEFAULT_HOMEHIGHLIGHTPROGRAMS_MODE = "latest";

const ROLES_ADMIN = [
  ROLES.BBB_ADMIN,
  ROLES.BBB_MANAGER_PUBLISHER,
  ROLES.BBB_MANAGER_ADVERTISER
];

export const FORM_HOME_HIGHLIGHT_PROGRAMS = {
  homeHighlightPrograms: {
    defaultValue: [],
    read: [
      ...ROLES_ADMIN,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ...ROLES_ADMIN,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ]
  },
  homeHighlightProgramsMode: {
    defaultValue: DEFAULT_HOMEHIGHLIGHTPROGRAMS_MODE,
    read: [
      ...ROLES_ADMIN,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ...ROLES_ADMIN,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ]
  }
};
