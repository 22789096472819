<template>
  <perfect-scrollbar class="container-domains ml-md-10 mt-5 mt-md-0 px-5">
    <div
      v-if="isLoadingConfig"
      class="d-flex flex-column h-100 w-100 justify-content-center align-items-center"
    >
      <b-spinner variant="primary" label="Spinning"></b-spinner>
      <span class="font-weight-bold mt-2"
        >{{ $tc("ADVANCED_SETTINGS.DOMAIN.LOADING") }}...</span
      >
    </div>

    <div
      v-if="noDisplayForm && isAdmin"
      class="d-flex justify-content-center align-items-center h-100"
    >
      <span class="font-weight-bold"
        >{{ $tc("ADVANCED_SETTINGS.DOMAIN.NO_DOMAIN") }}
        <router-link
          class="btn btn-primary text-white"
          :to="{ name: 'settings_domains_create' }"
          >{{ $tc("ADVANCED_SETTINGS.DOMAIN.CREATE_DOMAINE") }}</router-link
        >
      </span>
    </div>

    <div v-if="displayForm">
      <b-alert :show="errorInernalServer" variant="danger" dismissible
        >{{ $tc("ERROR_500") }}
      </b-alert>

      <div class="d-flex flex flex-column mt-0">
        <DomainsForm
          :formProps="domainForm"
          :domainId="getDomainId"
          :isCanCheck="isCanCheck"
          ref="domain"
          @host-input-change="handleInputHostChange"
          @company-select-change="handleCompanyChange"
        />
      </div>

      <LinksForm
        :formProps="formsLink"
        :domainId="getDomainId"
        :isCanCheck="isCanCheck"
        ref="links"
      />

      <CodeIntegration
        :formProps="formCodeIntegration"
        :domainId="getDomainId"
        :isCanCheck="isCanCheck"
        :domainName="domainForm.host"
        ref="codeIntegrations"
      />

      <SectionsSettings
        v-if="getDomainId"
        :isCanCheck="isCanCheck"
        :form="formSections"
        ref="sections"
      />

      <HomeHighlightProgramsForm
        v-if="getDomainId"
        :isCanCheck="isCanCheck"
        :form="formHomeHighlightPrograms"
        :programs="programs"
        ref="homeHighlightPrograms"
      />

      <DiscoverySettings
        v-if="Object.keys(formDiscoverySettings).length > 0"
        :formProps="formDiscoverySettings"
        :domainId="getDomainId"
        :hostname="hostname"
        :domainCompany="domainCompany"
        :userCompany="company"
        :isCanCheck="isCanCheck"
        ref="discoverySettings"
      />

      <AdvertisementForm
        v-if="isAdmin"
        :formProps="formAdvertisement"
        :domainId="getDomainId"
        ref="advertisement"
      />

      <ConfForm
        v-if="isAdmin"
        :formProps="formConf"
        :domainId="getDomainId"
        ref="conf"
      />

      <GoogleSearchForm
        v-if="isAdmin"
        :formProps="formGoogleSearch"
        :domainId="getDomainId"
        ref="googleSearch"
      />

      <OneSignalForm
        v-if="isAdmin"
        :formProps="formOneSignal"
        :domainId="getDomainId"
        ref="oneSignal"
      />

      <GPDRForm
        v-if="isAdmin"
        :formProps="formGDPR"
        :domainId="getDomainId"
        ref="gdpr"
      />

      <div class="d-flex justify-content-center mt-3">
        <button
          class="btn btn-primary"
          style="width: 121px"
          :disabled="isLoadingSubmit"
          @click="submit"
        >
          <template v-if="!isLoadingSubmit">
            {{ $tc("ADVANCED_SETTINGS.DOMAIN.SUBMIT") }}
          </template>

          <template v-if="isLoadingSubmit">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </button>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import {
  getConfigurationV2,
  updateDomainV2
} from "@/api/configurations/getters";
import { createDomainV2 } from "@/api/claim/setters";
import { mapGetters } from "vuex";
import DomainsForm from "@/components/settings/formsDomain/DomainsForm";
import LinksForm from "@/components/settings/formsDomain/LinksForm";
import AdvertisementForm from "@/components/settings/formsDomain/AdvertisementForm";
import ConfForm from "@/components/settings/formsDomain/ConfForm";
import GoogleSearchForm from "@/components/settings/formsDomain/GoogleSearchForm";
import OneSignalForm from "@/components/settings/formsDomain/OneSignalForm";
import GPDRForm from "@/components/settings/formsDomain/GPDRForm";
import ErrorService from "@/common/error.service";
import DiscoverySettings from "@/components/settings/formsDomain/DiscoverySettings";
import CodeIntegration from "@/components/settings/formsDomain/CodeIntegration";
import { elementCustomTemplate } from "@/components/settings/formsDomain/domain.config.js";
import SectionsSettings from "@/components/settings/formsDomain/SectionsSettings";
import { getAttributesRead, getAttributesWrite } from "@/common/config/acl";
import { FORM_DOMAINS } from "@/common/config/acl/domain/domains";
import auth from "@/store/auth.module";
import { FORM_LINKS } from "@/common/config/acl/domain/links";
import { FORM_GPDR } from "@/common/config/acl/domain/gpdr";
import { FORM_ONE_SIGNAL } from "@/common/config/acl/domain/oneSignal";
import { FORM_GOOGLE_SEARCH } from "@/common/config/acl/domain/googleSearch";
import { FORM_CONF } from "@/common/config/acl/domain/conf";
import { FORM_DISCOVERY } from "@/common/config/acl/domain/discovery";
import { FORM_ADVERTISEMENT } from "@/common/config/acl/domain/advertisement";
import { FORM_HOME_HIGHLIGHT_PROGRAMS } from "@/common/config/acl/domain/homeHighlightPrograms";
import HomeHighlightProgramsForm from "@/components/settings/formsDomain/HomeHighlightProgramsForm";

export default {
  components: {
    DiscoverySettings,
    DomainsForm,
    LinksForm,
    AdvertisementForm,
    ConfForm,
    GoogleSearchForm,
    OneSignalForm,
    GPDRForm,
    CodeIntegration,
    SectionsSettings,
    HomeHighlightProgramsForm
  },

  props: {
    domains: {
      type: Array
    },
    company: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      user: auth.state.user,
      hostname: "",
      domainCompany: {},
      domainForm: {},
      formsLink: {},
      formDiscoverySettings: {},
      formCodeIntegration: {},
      formAdvertisement: {},
      formConf: {},
      formGoogleSearch: {},
      formOneSignal: {},
      formGDPR: {},
      formSections: [],
      formHomeHighlightPrograms: {},
      programs: [],
      body: {},
      listCompanies: [],
      isLoadingSubmit: false,
      isLoadingConfig: false,
      isCreate: false,
      errorInernalServer: false,
      openAutoComplete: false,
      isCanCheck: false
    };
  },

  mounted() {
    this.formDiscoverySettings = getAttributesRead(
      FORM_DISCOVERY,
      this.user.roles,
      { discovery: { cssSelector: [], debug: false } }
    );

    if (this.getDomainId) {
      this.loadConfigurations(this.getDomainId);
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: "isAdmin"
    }),

    noDisplayForm() {
      return !this.$route.params.id && !this.isCreatingDomain;
    },

    displayForm() {
      return (
        (this.$route.params.id || this.isCreatingDomain) &&
        !this.isLoadingConfig
      );
    },

    isCreatingDomain() {
      return this.$route.path.includes("create");
    },

    getDomainId() {
      return this.$route.params.id;
    }
  },

  methods: {
    async loadConfigurations(domainId) {
      this.isLoadingConfig = true;
      this.errorInernalServer = false;

      try {
        const response = await getConfigurationV2(domainId, this.axios);

        this.hostname = response.host;

        this.domainForm = getAttributesRead(
          FORM_DOMAINS,
          this.user.roles,
          response
        );

        Object.assign(this.domainForm, {
          company: response.company.id,
          companyObject: response.company
        });

        this.domainCompany = response.company;

        this.formsLink = getAttributesRead(
          FORM_LINKS,
          this.user.roles,
          response
        );

        this.formHomeHighlightPrograms = getAttributesRead(
          FORM_HOME_HIGHLIGHT_PROGRAMS,
          this.user.roles,
          response
        );

        const programDomains = response?.programDomains || [];

        this.programs = programDomains
          .filter(
            programDomain =>
              programDomain.program.parentId === null &&
              programDomain.program.homeDisplay === true
          )
          .map(programDomain => ({
            id: programDomain.program.shortened,
            name: programDomain.program.name
          }))
          // keep order such as select and sort by name last
          .sort((a, b) => {
            const indexA = this.formHomeHighlightPrograms.homeHighlightPrograms.indexOf(
              a.id
            );
            const indexB = this.formHomeHighlightPrograms.homeHighlightPrograms.indexOf(
              b.id
            );

            if (indexA !== -1 && indexB !== -1) {
              return indexA - indexB;
            }

            if (indexA !== -1) return -1;
            if (indexB !== -1) return 1;

            return a.name.localeCompare(b.name);
          });

        this.formCodeIntegration = { ...response.customTemplate };
        this.formCodeIntegration.activatedCustomContent =
          response.activatedCustomContent;

        if (
          !("cssSelector" in response.discovery) ||
          !Array.isArray(response.discovery.cssSelector)
        ) {
          response.discovery = FORM_DISCOVERY.discovery.defaultValue;
        }

        this.formDiscoverySettings = getAttributesRead(
          FORM_DISCOVERY,
          this.user.roles,
          response
        );

        this.formAdvertisement = getAttributesRead(
          FORM_ADVERTISEMENT,
          this.user.roles,
          response
        );

        this.formConf = getAttributesRead(FORM_CONF, this.user.roles, response);

        this.formGoogleSearch = getAttributesRead(
          FORM_GOOGLE_SEARCH,
          this.user.roles,
          response
        );

        this.formOneSignal = getAttributesRead(
          FORM_ONE_SIGNAL,
          this.user.roles,
          response
        );

        this.formGDPR = getAttributesRead(FORM_GPDR, this.user.roles, response);

        this.formSections = response.sections.sort(
          (a, b) => a.indexOrder - b.indexOrder
        );
      } catch (error) {
        console.error("---ERROR-LOAD-CONFIGURATIONS---");
        console.error(error);

        ErrorService.displayErrorMsg(error);

        this.errorInernalServer = true;
      }
      this.isLoadingConfig = false;
    },

    async createConfiguration() {
      this.errorInernalServer = false;
      this.isLoadingSubmit = true;

      try {
        const response = await createDomainV2(this.body, this.axios);

        this.showToast({
          title: this.$tc("ADVANCED_SETTINGS.DOMAIN.TOAST.SUCCESS.TITLE"),
          message: this.$tc(
            "ADVANCED_SETTINGS.DOMAIN.TOAST.SUCCESS.MESSAGE_CREATE"
          ),
          variant: "success"
        });

        this.$emit("reloadDomain", true);
        this.$router.push({
          name: "settings_domain_edit",
          params: { id: response.id }
        });
      } catch (error) {
        console.error("---ERROR-CREATE-CONFIGURATION---");
        console.error(error);

        ErrorService.displayErrorMsg(error);

        if (error.status === 500) {
          this.errorInernalServer = true;
        }
      }

      this.isLoadingSubmit = false;
    },

    async updateConfiguration() {
      this.errorInernalServer = false;
      this.isLoadingSubmit = true;

      try {
        await updateDomainV2(this.$route.params.id, this.body, this.axios);

        this.showToast({
          title: this.$tc("ADVANCED_SETTINGS.DOMAIN.TOAST.SUCCESS.TITLE"),
          message: this.$tc(
            "ADVANCED_SETTINGS.DOMAIN.TOAST.SUCCESS.MESSAGE_UPDATE"
          ),
          variant: "success"
        });

        this.loadConfigurations(this.getDomainId);
        this.$emit("reloadDomain", true);
      } catch (error) {
        console.error("---ERROR-UPDATE-CONFIUGRATION---");
        console.error(error);

        ErrorService.displayErrorMsg(error);

        if (error.status === 500) {
          this.errorInernalServer = true;
        }
      }

      this.isLoadingSubmit = false;
    },

    resetData() {
      const refs = Object.keys(this.$refs);
      refs.forEach(key => {
        if (this.$refs[key]) {
          this.$refs[key].resetData();
        }
      });
    },

    prepareBodyRequest() {
      const domain = this.$refs.domain;
      const links = this.$refs.links;
      const homeHighlightPrograms = this.$refs.homeHighlightPrograms;

      this.body = {
        ...getAttributesWrite(domain.form, FORM_DOMAINS, auth.state.user.roles),
        ...getAttributesWrite(links.form, FORM_LINKS, auth.state.user.roles),
        ...getAttributesWrite(
          homeHighlightPrograms.formData,
          FORM_HOME_HIGHLIGHT_PROGRAMS,
          auth.state.user.roles
        )
      };

      //TODO: Remove after removing siteTitle & description from db
      if (this.body.multilingualContent !== undefined) {
        this.body.siteTitle = this.body.multilingualContent.default.title;
        this.body.description = this.body.multilingualContent.default.tagline;
      }

      if (Object.keys(this.formDiscoverySettings).length > 0) {
        const discovery = this.$refs.discoverySettings;

        this.body = {
          ...this.body,
          ...getAttributesWrite(
            discovery.form,
            FORM_DISCOVERY,
            auth.state.user.roles
          )
        };
      }

      if (this.isAdmin) {
        const advertisement = this.$refs.advertisement;
        const conf = this.$refs.conf;
        const googleSearch = this.$refs.googleSearch;
        const oneSignal = this.$refs.oneSignal;
        const gdpr = this.$refs.gdpr;

        // Force convert json string to json object
        const advertisementForm = {
          ...advertisement.form,
          sellers: JSON.parse(advertisement.form.sellers)
        };

        const googleSearchForm = {
          ...googleSearch.form,
          robotsGoogleVerificationOwner: JSON.parse(
            googleSearch.form.robotsGoogleVerificationOwner
          )
        };
        const gdprForm = {
          ...gdpr.form,
          cmp: JSON.parse(gdpr.form.cmp)
        };

        this.body = {
          ...this.body,
          ...getAttributesWrite(
            advertisementForm,
            FORM_ADVERTISEMENT,
            auth.state.user.roles
          ),
          ...getAttributesWrite(conf.form, FORM_CONF, auth.state.user.roles),

          ...getAttributesWrite(
            googleSearchForm,
            FORM_GOOGLE_SEARCH,
            auth.state.user.roles
          ),
          ...getAttributesWrite(
            oneSignal.form,
            FORM_ONE_SIGNAL,
            auth.state.user.roles
          ),
          ...getAttributesWrite(gdprForm, FORM_GPDR, auth.state.user.roles)
        };
      }

      const codeIntegrations = this.$refs.codeIntegrations;
      let customHTMLPage = {};

      codeIntegrations.customTemplate.forEach(element => {
        const find = elementCustomTemplate.find(el => element.labelType === el);
        if (find) {
          customHTMLPage[find] = { ...element };
          const filtersCodeIntegrations = codeIntegrations.customTemplate.filter(
            e => e.type !== element.type
          );
          codeIntegrations.customTemplate = [...filtersCodeIntegrations];
        }
      });

      const headerHTML =
        codeIntegrations.customTemplate.find(
          element => element.type === "headerHTML"
        ) || {};

      Object.assign(this.body, {
        activatedCustomContent: codeIntegrations.activatedCustomContent,
        brandLogo: null,
        customTemplate: {
          headScripts: codeIntegrations.customTemplate,
          headerHTML: headerHTML,
          additionalHTML: customHTMLPage
        }
      });

      const domainId = this.$route.params.id;
      if (domainId) {
        const sections = this.$refs.sections;

        sections.sections.forEach((element, index) => {
          element.indexOrder = index;
          element.domain = domainId;
        });

        this.body.id = this.$route.params.id;
        this.body.sections = sections.sections.sort(
          (a, b) => a.indexOrder - b.indexOrder
        );
      }
    },

    submit() {
      const domain = this.$refs.domain;
      const links = this.$refs.links;
      const codeIntegrations = this.$refs.codeIntegrations;
      this.isCanCheck = true;

      setTimeout(() => {
        const condition =
          !domain.hasErrors &&
          !links.hasErrors &&
          !codeIntegrations.hasErrors();

        let checkSections = true;

        if (this.getDomainId) {
          const sections = this.$refs.sections;
          checkSections = !sections.hasErrors();
        }

        let checkDiscoverySettings = true;

        if (Object.keys(this.formDiscoverySettings).length > 0) {
          const discoverySettings = this.$refs.discoverySettings;
          checkDiscoverySettings = !discoverySettings.hasErrors();
        }

        if (condition && checkSections && checkDiscoverySettings) {
          this.prepareBodyRequest();
          if (this.$route.params.id) {
            this.updateConfiguration();
          } else {
            this.createConfiguration();
          }
        }
      }, 20);
    },

    showToast({ title, variant, message }) {
      const h = this.$createElement;
      const id = `toaster-message`;
      const $contentToaster = h("span", message);

      this.$bvToast.toast([$contentToaster], {
        id: id,
        title: title,
        variant: variant,
        noCloseButton: false
      });
    },

    handleInputHostChange(value) {
      this.hostname = value;
    },

    handleCompanyChange(value) {
      this.domainCompany = value;
    }
  },

  watch: {
    domains: function(values) {
      if (values.length === 1) {
        this.$router.push({
          name: "settings_domain_edit",
          params: { id: values[0].id }
        });
      }
    },
    "$route.query"() {
      this.resetData();

      if (this.getDomainId) {
        this.loadConfigurations(this.getDomainId);
      }
    }
  }
};
</script>

<style scoped>
.container-domains {
  width: 100%;
  min-height: 300px;
}

@media (min-width: 960px) {
  .container-domains {
    width: 100%;
    height: 905px;
  }
}
</style>
