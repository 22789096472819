var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion accordion-solid accordion-toggle-plus",attrs:{"id":"accordion-form-advanced"}},[_c('div',{staticClass:"card m-3"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title collapsed py-3 pr-10 font-weight-normal",attrs:{"data-toggle":"collapse","data-target":"#home_highlight_programs"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc( "ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.TITLE" ))+" ")])])]),_c('div',{staticClass:"collapse",attrs:{"id":"home_highlight_programs","data-parentId":"#accordion-form-home-highlight-programs"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true"}},[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.$tc(
                'ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.MESSAGE'
              )
            )}})]),(_vm.hasAccess('homeHighlightPrograms', 'read'))?_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"},[_c('label',{staticClass:"mr-2 mb-0 w-25"},[_vm._v(_vm._s(_vm.$tc( "ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.PROGRAMS.LABEL" ))+" "),_c('HelperTooltipHTML',{attrs:{"icon":"mdi-help-circle","text":_vm.$tc(
                  'ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.PROGRAMS.HELPER'
                )}})],1),_c('div',{staticClass:"d-flex flex-column w-75"},[_c('TagSelect',{attrs:{"listOptions":_vm.programs,"buttonText":_vm.$tc(
                  'ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.PROGRAMS.BUTTONTEXT'
                ),"buttonIcon":"mdi-flag","fieldName":"name","variant":"info","preSelectedElement":_vm.formData.homeHighlightPrograms,"disabled":!_vm.hasAccess('homeHighlightPrograms', 'write')},on:{"change":_vm.changeHomeHighlightPrograms}})],1)]):_vm._e(),(_vm.hasAccess('homeHighlightProgramsMode', 'read'))?_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"},[_c('label',{staticClass:"mr-2 mb-0 w-25"},[_vm._v(_vm._s(_vm.$tc( "ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.MODE.LABEL" ))+" "),_c('HelperTooltipHTML',{attrs:{"icon":"mdi-help-circle","text":_vm.$tc(
                  'ADVANCED_SETTINGS.DOMAIN.CONFIG_HOME_HIGHLIGHT_PROGRAMS.SELECTOR.MODE.HELPER'
                )}})],1),_c('div',{staticClass:"d-flex flex-column w-75"},[_c('b-form-select',{staticClass:"form-control--grey",attrs:{"options":_vm.EnumHomeHighlightProgramsMode,"disabled":!_vm.hasAccess('homeHighlightProgramsMode', 'write')},model:{value:(_vm.formData.homeHighlightProgramsMode),callback:function ($$v) {_vm.$set(_vm.formData, "homeHighlightProgramsMode", $$v)},expression:"formData.homeHighlightProgramsMode"}})],1)]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }